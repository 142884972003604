.Qa {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;

  cursor: pointer;
}

.Qa .Question {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.Qa .Question img {
  width: 25px;
  height: 25px;
}

.Qa img.Rotate {
  transform: rotate(90deg);
}

.Qa .Answer {
  margin-bottom: 15px;
}

.Qa .Hidden {
  display: none;
}
