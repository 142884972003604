body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Signup_Signup__69fVe {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 1150px) {
  .Signup_Signup__69fVe {
    grid-template-columns: minmax(320px, 792px) 1fr;
    grid-template-rows: min-content 1fr;
  }

  .Signup_Signup__69fVe > div:nth-of-type(2) {
    order: 3;
  }

  .Signup_Signup__69fVe > div:nth-of-type(3) {
    order: 2;
  }
}

.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 15%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.Header_Header__RtNKV {
  background-color: #34b558;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.Header_Header__RtNKV img {
  max-height: 46px;
}

.Header_Header__RtNKV .Header_Menu__2tdkO {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}

.Header_Header__RtNKV .Header_Menu__2tdkO div + div {
  margin-left: 20px;
}

.Header_Header__RtNKV .Header_Black__1CQcT {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.Header_Header__RtNKV .Header_White__1MbnY {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: white;
}

.Header_Header__RtNKV .Header_White__1MbnY a,
.Header_Header__RtNKV .Header_White__1MbnY a:active {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: white;
  text-decoration: none;
}

@media (min-width: 700px) {
  .Header_Header__RtNKV {
    padding: 38px 50px;
    flex-direction: row;
    align-items: stretch;
  }
}

@media (min-width: 1150px) {
  .Header_Header__RtNKV {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 1450px) {
  .Header_Header__RtNKV {
    flex-direction: row;
    align-items: stretch;
  }
}

.LatesInfo_LatesInfo__1XK3r {
  background-color: #34b558;
  background: linear-gradient(to bottom, #34b558 0%,#34b57a 100%);
  padding: 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.LatesInfo_LatesInfo__1XK3r .LatesInfo_Illustration__sGvNq {
  width: 320px;
  height: 302px;
  max-width: 100%;
}

.LatesInfo_LatesInfo__1XK3r .LatesInfo_Info__2U5Es {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18.67px;
  color: white;
  line-height: 37.3px;
  margin-bottom: 75px;
}

@media (min-width: 700px) {
  .LatesInfo_LatesInfo__1XK3r {
    padding: 38px 50px;
  }
}

.ReviewCard_ReviewCard__1WxY1 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  background-color: #c2ffdb;
  padding: 15px 25px;
  border-radius: 20px;
}

.ReviewCard_ReviewCard__1WxY1 img {
  width: 40px;
  height: 40px;
}

.ReviewCard_ReviewCard__1WxY1 .ReviewCard_date__Er2VD {
  color: #939393;
}

.ReviewCaroussel_ReviewCaroussel__18CBF {
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 80px;
}

.ReviewCaroussel_ReviewCaroussel__18CBF .ReviewCaroussel_buttons__2F8QE {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.ReviewCaroussel_ReviewCaroussel__18CBF .ReviewCaroussel_buttons__2F8QE > div {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  background-color: #00ad54;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.ReviewCaroussel_ReviewCaroussel__18CBF .ReviewCaroussel_buttons__2F8QE > div:nth-child(1) {
  margin-right: 10px;
}
.ReviewCaroussel_ReviewCaroussel__18CBF .ReviewCaroussel_buttons__2F8QE > div > div {
  position: relative;
  top: 1px;
}

.ReviewCaroussel_ReviewCaroussel__18CBF > div + div {
  border-top: 1px solid #64e3a1;
}

.ReviewCaroussel_ReviewCaroussel__18CBF .ReviewCaroussel_fadeIn__tjhIk {
  animation-name: ReviewCaroussel_fadeIn__tjhIk;
  animation-duration: 1s;
}

@keyframes ReviewCaroussel_fadeIn__tjhIk {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ReviewCaroussel_ReviewCaroussel__18CBF .ReviewCaroussel_fadeOut__Ap9O9 {
  animation-name: ReviewCaroussel_fadeOut__Ap9O9;
  animation-duration: 1s;
}

@keyframes ReviewCaroussel_fadeOut__Ap9O9 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.Faq_Faq__2wW6U {
 text-align: left;
 margin: 0 20px;
}

.Faq_Faq__2wW6U h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 18.67px;
  color: #404041;
}

.Faq_Faq__2wW6U > div + div {
  border-top: 1px solid #64e3a1;
}

.Qa_Qa__3FYww {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;

  cursor: pointer;
}

.Qa_Qa__3FYww .Qa_Question__2aG2a {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.Qa_Qa__3FYww .Qa_Question__2aG2a img {
  width: 25px;
  height: 25px;
}

.Qa_Qa__3FYww img.Qa_Rotate__2Im3Y {
  transform: rotate(90deg);
}

.Qa_Qa__3FYww .Qa_Answer__1ZXb0 {
  margin-bottom: 15px;
}

.Qa_Qa__3FYww .Qa_Hidden__3JqlS {
  display: none;
}

.ClubInfo_ClubInfo__1wWMV {
  background-color: #34b558;
  background: linear-gradient(to bottom, #34b558 0%, #34b57a 100%);
  padding: 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ClubInfo_ClubInfo__1wWMV .ClubInfo_Logo__3CteB {
  font-size: 24px;
}

.ClubInfo_ClubInfo__1wWMV .ClubInfo_Logo__3CteB img {
  width: 250px;
  height: 250px;
  max-width: 100%;
  font-size: 24px;
}

.ClubInfo_ClubInfo__1wWMV .ClubInfo_Info__37d4r {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18.67px;
  color: white;
  line-height: 37.3px;
  margin-bottom: 75px;
}

@media (min-width: 700px) {
  .ClubInfo_ClubInfo__1wWMV {
    padding: 38px 50px;
  }
}

.ProgressBar_ProgressBar__21QGr {
  display: none;
}

@media (min-width: 700px) {
  .ProgressBar_ProgressBar__21QGr {
    padding: 38px 50px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .ProgressBar_ProgressBar__21QGr > div + div {
    margin-left: 44px;
  }

  .ProgressBar_ProgressBar__21QGr div {
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    font-size: 21.3px;
    text-transform: uppercase;
    color: #404041;
    height: 32px;
    border-bottom: 5px solid #ededed;
  }

  .ProgressBar_ProgressBar__21QGr div.ProgressBar_Active__1BKsz {
    border-bottom-color: #34b558;
  }
}

.Page_Page__5B4fD {
  padding: 25px;
  display: flex;
  justify-content: center;
  position: relative;
}

.Page_Page__5B4fD h1 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  font-size: 25px;
  text-transform: uppercase;
  color: #404041;
  margin-top: 0;
  margin-bottom: 6px;
}

.Page_Page__5B4fD h2 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #404041;
  margin-bottom: 6px;
}

.Page_Page__5B4fD p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  margin-top: 0;
  margin-bottom: 40px;
}

.Page_Page__5B4fD hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #cdcdcd;
  margin: 45px 0;
  padding: 0;
}

@media (min-width: 700px) {
  .Page_Page__5B4fD {
    padding: 38px 50px;
  }

  .Page_Page__5B4fD h1 {
    font-size: 40px;
  }
}

@media (min-width: 1150px) {
  .Page_Page__5B4fD {
    min-width: 650px;
  }
}

.Account_Account__3IIzQ {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.Account_Account__3IIzQ .Account_Forms__1SEK2 {
  max-width: 600px;
  margin: auto;
}

.Account_Account__3IIzQ .Account_Next__6tRcO {
  margin-top: 40px;
  margin-bottom: 50px;
}

.Form_Form__1tlJp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
}

.Form_Form__1tlJp h2 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #404041;
  margin-bottom: 6px;
}

.Form_Form__1tlJp p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #404041;
  margin-bottom: 15px;
}

.Input_Input__2cnOb {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  position: relative;
  width: 100%;
  background: transparent;
}

.Input_Input__2cnOb input {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  width: 100%;
  margin: 25px 0;
  border: none;
  border-bottom: 3px solid #34b558;
  outline: none;
  padding: 5px 0;
  color: #404041;
  background: transparent;
}

.Input_Input__2cnOb input[type='checkbox'],
.Input_Input__2cnOb input[type='radio'] {
  width: auto;
  margin: 25px 10px 25px 0;
  border: none;
  border-bottom: 3px solid #34b558;
  outline: none;
  padding: 5px 0;
  color: #404041;
  background: transparent;
}

.Input_Input__2cnOb.Input_MovingPlaceholder__2rzSZ span {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 30px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.Input_Input__2cnOb.Input_MovingPlaceholder__2rzSZ.Input_HasFocus__1YazA span {
  top: 6px;
  font-size: 14.67px;
  color: #34b558;
}

.Input_Input__2cnOb.Input_Error__bV8-m input {
  border-bottom: 3px solid red;
}

.Input_Input__2cnOb.Input_Error__bV8-m span {
  color: red;
}

.Input_Input__2cnOb label {
  cursor: pointer;
}

.Input_Input__2cnOb input[type="radio"] {
  transform: scale(1.3); /* Increases size of the radio button */
  margin-right: 8px;
  cursor: pointer;
}

@media (min-width: 480px) {
  .Input_Input__2cnOb.Input_Half__3vRKD {
    width: calc(50% - 22px);
  }
}

.Button_Button__1MJdh {
  background-color: #34b558;
  background: linear-gradient(to right, #34b558 0%, #34b57a 75%);
  border: none;
  border-radius: 25px;
  outline: none;
  height: 50px;
  padding: 0 25px;
  cursor: pointer;

  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #363032;
}

.Button_Button__1MJdh:hover {
  color: white;
}

.ErrorMessage_ErrorMessage__2765F {
  background-color: #f2ac31;
  display: flex;
  align-items: center;
  border-radius: 6.67px;
  padding: 25px 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: white;
  width: 100%;
}

.ErrorMessage_ErrorMessage__2765F img {
  display: block;
  width: 44px;
  height: 45px;
  margin-right: 25px;
}

.ErrorMessage_ErrorMessage__2765F div {
  width: auto !important;
}

.Carriers_Carriers__LZ28a {
  width: 100%;
  max-width: 800px;
}

.Carriers_Carriers__LZ28a h1 {
  text-align: center;
  margin-bottom: 50px;
}

.Carriers_Carriers__LZ28a .Carriers_CarrierButtons__36QK- {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 190px);
  /* justify-content: center; */

  margin-top: 40px;
  margin-bottom: 65px;
}

.Carriers_StickyAction__13Q_K {
  box-shadow: 0px -2px 9px 1px rgba(123, 123, 123, 0.15);
  background-color: white;
  padding: 25px 0;

  position: fixed;
  bottom: 0;

  text-align: center;
}

.CarrierButton_CarrierButton__xaTxr {
  width: 100%;
  height: 142px;
  background-color: #f4f7f9;
  border-radius: 13.33px;
  border: none;
  outline: none;
  padding: 40px 15px;
  cursor: pointer;
  position: relative;
  margin-bottom: 12.5px;
}

.CarrierButton_CarrierButton__xaTxr img.CarrierButton_Logo__3xNN6 {
  max-width: 100%;
  max-height: 100%;
}

.CarrierButton_CarrierButton__xaTxr .CarrierButton_AddButtonInactive__2P0BL {
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: -12.5px;
  left: calc(50% - 12.5px);
}

.CarrierButton_CarrierButton__xaTxr:hover .CarrierButton_AddButtonInactive__2P0BL {
  display: none;
}

.CarrierButton_CarrierButton__xaTxr .CarrierButton_AddButton__sWD8r {
  display: none;
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: -12.5px;
  left: calc(50% - 12.5px);
}

.CarrierButton_CarrierButton__xaTxr:hover .CarrierButton_AddButton__sWD8r {
  display: block;
}

.CarrierBox_CarrierBox__X6HF1 {
  margin-top: 20px;
  margin-bottom: 45px;
}

.CarrierBox_CarrierBox__X6HF1 .CarrierBox_Title__3U13N {
  background-color: #404041;
  border-radius: 13.33px 13.33px 0 0;
  padding: 22px 25px;
  display: flex;
  justify-content: space-between;

  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: white;
}

.CarrierBox_CarrierBox__X6HF1 .CarrierBox_Title__3U13N button {
  background: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.CarrierBox_CarrierBox__X6HF1 .CarrierBox_Title__3U13N button img {
  width: 16px;
  height: 16px;
}

.CarrierBox_BottomWorking__3-9Ig {
  background-color: #737373;
  display: flex;
  align-items: center;
  border-radius: 0 0 6.67px 6.67px;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.CarrierBox_BottomWorking__3-9Ig img {
  display: block;
  width: 44px;
  height: 45px;
  margin-right: 25px;
}

.CarrierBox_BottomDone__1r7UA {
  background-color: #30a95c;
  display: flex;
  align-items: center;
  border-radius: 0 0 6.67px 6.67px;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.CarrierBox_BottomDone__1r7UA img {
  display: block;
  width: 27px;
  height: 27px;
  margin-right: 15px;
}

@media (min-width: 700px) {
  .CarrierBox_BottomWorking__3-9Ig,
  .CarrierBox_BottomDone__1r7UA {
    padding: 15px 60px;
  }
}

.ConnectStep_ConnectStep__616z3 {
  background-color: #f4f7f9;
  padding: 25px;
}

.ConnectStep_ConnectStep__616z3 p {
  font-size: 16px;
  line-height: 24px;
  color: #404041;
  width: 100%;
}

.ConnectStep_ConnectStep__616z3 div {
  width: 100%;
}

.ConnectStep_ConnectStep__616z3 .ConnectStep_textDanger__fBeJd {
  color: red;
}

.ConnectStep_ConnectStep__616z3 .ConnectStep_small__1Ozmb {
  font-size: 14px;
}

.ConnectStep_ConnectStep__616z3 .ConnectStep_hidden__2_-pk {
  display: none;
}

.ConnectStep_ConnectStep__616z3 .ConnectStep_Button__2g0la {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 700px) {
  .ConnectStep_ConnectStep__616z3 {
    padding: 45px 60px;
  }
}

.ClubInfo_ClubInfo__1lVgi {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.ClubInfo_ClubInfo__1lVgi > p {
  margin-bottom: 5px;
}

.ClubInfo_ClubInfo__1lVgi .ClubInfo_Forms__x5JNw {
  max-width: 600px;
  margin: auto;
}

.ClubInfo_ClubInfo__1lVgi .ClubInfo_NotRequired__2j52u {
  text-align: left;
}

.ClubInfo_ClubInfo__1lVgi .ClubInfo_NotRequired__2j52u h2 {
  color: #8e8e8e;
}

.ClubInfo_ClubInfo__1lVgi .ClubInfo_NotRequired__2j52u p {
  color: #8e8e8e;
}

.ClubInfo_ClubInfo__1lVgi .ClubInfo_Next__tA5qt {
  margin-top: 40px;
  margin-bottom: 50px;
}

.Select_Select__1zmp5 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  position: relative;
  width: 100%;
  background: transparent;
}

.Select_Select__1zmp5 select {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  width: 100%;
  margin: 25px 0;
  border: none;
  border-bottom: 3px solid #34b558;
  outline: none;
  padding: 5px 0;
  color: #404041;
  background: transparent;
}

.Select_Select__1zmp5 span {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 30px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.Select_Select__1zmp5.Select_HasFocus__2-hHk span {
  top: 6px;
  font-size: 14.67px;
  color: #34b558;
}

.Select_Select__1zmp5.Select_Error__257Cp select {
  border-bottom: 3px solid red;
}

.Select_Select__1zmp5.Select_Error__257Cp span {
  color: red;
}

@media (min-width: 480px) {
  .Select_Select__1zmp5.Select_Half__Be7MZ {
    width: calc(50% - 22px);
  }
}

.ClaimInfo_ClaimInfo__2bED6 {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.ClaimInfo_ClaimInfo__2bED6 > p {
  margin-bottom: 5px;
}

.ClaimInfo_ClaimInfo__2bED6 .ClaimInfo_Forms__tUGjC {
  max-width: 600px;
  margin: auto;
}

.ClaimInfo_ClaimInfo__2bED6 .ClaimInfo_NotRequired__3sF1n {
  text-align: left;
}

.ClaimInfo_ClaimInfo__2bED6 .ClaimInfo_NotRequired__3sF1n h2 {
  color: #8e8e8e;
}

.ClaimInfo_ClaimInfo__2bED6 .ClaimInfo_NotRequired__3sF1n p {
  color: #8e8e8e;
}

.ClaimInfo_ClaimInfo__2bED6 .ClaimInfo_Next__3LNU8 {
  margin-top: 40px;
  margin-bottom: 50px;
}

.ClaimInfo_StripeForm__3S73J {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.StripeInput_StripeInput__pAP1z {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  background: transparent;

  position: relative;
  width: 100%;
  padding: 25px 0;
}

.StripeInput_StripeInput__pAP1z label {
  pointer-events: none;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 28px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.StripeInput_StripeInput__pAP1z.StripeInput_HasFocus__-_Lhx label {
  top: 6px;
  font-size: 14.67px;
  color: #34b558;
}

.StripeInput_StripeInput__pAP1z.StripeInput_Error__3z9Ms label {
  color: red;
}

.StripeInput_StripeInput__pAP1z > div {
  border-bottom: 3px solid #34b558;
  padding: 5px 0;
}

.StripeInput_StripeInput__pAP1z.StripeInput_Error__3z9Ms > div {
  border-bottom: 3px solid red;
}

@media (min-width: 480px) {
  .StripeInput_StripeInput__pAP1z.StripeInput_Half__3_6mf {
    width: calc(50% - 22px);
  }
}

.CompanyInfo_CompanyInfo__37pI7 {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.CompanyInfo_CompanyInfo__37pI7 > p {
  margin-bottom: 5px;
}

.CompanyInfo_CompanyInfo__37pI7 .CompanyInfo_Forms__1R1n4 {
  max-width: 600px;
  margin: auto;
}

.CompanyInfo_CompanyInfo__37pI7 .CompanyInfo_NotRequired__QOr9y {
  text-align: left;
}

.CompanyInfo_CompanyInfo__37pI7 .CompanyInfo_NotRequired__QOr9y h2 {
  color: #8e8e8e;
}

.CompanyInfo_CompanyInfo__37pI7 .CompanyInfo_NotRequired__QOr9y p {
  color: #8e8e8e;
}

.CompanyInfo_CompanyInfo__37pI7 .CompanyInfo_Next__2GH-M {
  margin-top: 40px;
  margin-bottom: 50px;
}

.CompanyInfo_StripeForm__1O3up {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.StripeInput_StripeInput__1O6Wh {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  background: transparent;

  position: relative;
  width: 100%;
  padding: 25px 0;
}

.StripeInput_StripeInput__1O6Wh label {
  pointer-events: none;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 28px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.StripeInput_StripeInput__1O6Wh.StripeInput_HasFocus__2INkG label {
  top: 6px;
  font-size: 14.67px;
  color: #34b558;
}

.StripeInput_StripeInput__1O6Wh.StripeInput_Error__1b7qv label {
  color: red;
}

.StripeInput_StripeInput__1O6Wh > div {
  border-bottom: 3px solid #34b558;
  padding: 5px 0;
}

.StripeInput_StripeInput__1O6Wh.StripeInput_Error__1b7qv > div {
  border-bottom: 3px solid red;
}

@media (min-width: 480px) {
  .StripeInput_StripeInput__1O6Wh.StripeInput_Half__1dqdZ {
    width: calc(50% - 22px);
  }
}

.Confirmation_Confirmation__1USdx {
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.Confirmation_Confirmation__1USdx p {
  margin-bottom: 5px;
}

.Confirmation_Confirmation__1USdx .Confirmation_Next__1ysCm {
  margin-top: 40px;
  margin-bottom: 50px;
}

.HubspotForm_Account__3X8xx {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.HubspotForm_Account__3X8xx .HubspotForm_Forms__2SKh6 {
  max-width: 600px;
  margin: auto;
}

.HubspotForm_Account__3X8xx .HubspotForm_Next__1KqSs {
  margin-top: 40px;
  margin-bottom: 50px;
}

.HubspotForm_Account__3X8xx input.HubspotForm_primary__20_W9 {
  background-color: #34b558;
  background: linear-gradient(to right, #34b558 0%, #34b57a 75%);
  border: none;
  border-radius: 25px;
  outline: none;
  height: 50px;
  padding: 0 25px;
  cursor: pointer;

  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #363032;
}

.HubspotForm_Account__3X8xx input.HubspotForm_primary__20_W9 {
  color: white;
}

.Loader_Loader__3Hvr0 circle {
  fill: #34b55f;
}

