.ClaimInfo {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.ClaimInfo > p {
  margin-bottom: 5px;
}

.ClaimInfo .Forms {
  max-width: 600px;
  margin: auto;
}

.ClaimInfo .NotRequired {
  text-align: left;
}

.ClaimInfo .NotRequired h2 {
  color: #8e8e8e;
}

.ClaimInfo .NotRequired p {
  color: #8e8e8e;
}

.ClaimInfo .Next {
  margin-top: 40px;
  margin-bottom: 50px;
}

.StripeForm {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
