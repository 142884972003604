.ClubInfo {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.ClubInfo > p {
  margin-bottom: 5px;
}

.ClubInfo .Forms {
  max-width: 600px;
  margin: auto;
}

.ClubInfo .NotRequired {
  text-align: left;
}

.ClubInfo .NotRequired h2 {
  color: #8e8e8e;
}

.ClubInfo .NotRequired p {
  color: #8e8e8e;
}

.ClubInfo .Next {
  margin-top: 40px;
  margin-bottom: 50px;
}
