.CarrierBox {
  margin-top: 20px;
  margin-bottom: 45px;
}

.CarrierBox .Title {
  background-color: #404041;
  border-radius: 13.33px 13.33px 0 0;
  padding: 22px 25px;
  display: flex;
  justify-content: space-between;

  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: white;
}

.CarrierBox .Title button {
  background: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.CarrierBox .Title button img {
  width: 16px;
  height: 16px;
}

.BottomWorking {
  background-color: #737373;
  display: flex;
  align-items: center;
  border-radius: 0 0 6.67px 6.67px;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.BottomWorking img {
  display: block;
  width: 44px;
  height: 45px;
  margin-right: 25px;
}

.BottomDone {
  background-color: #30a95c;
  display: flex;
  align-items: center;
  border-radius: 0 0 6.67px 6.67px;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.BottomDone img {
  display: block;
  width: 27px;
  height: 27px;
  margin-right: 15px;
}

@media (min-width: 700px) {
  .BottomWorking,
  .BottomDone {
    padding: 15px 60px;
  }
}
