.Form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
}

.Form h2 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #404041;
  margin-bottom: 6px;
}

.Form p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #404041;
  margin-bottom: 15px;
}
