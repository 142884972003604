.Faq {
 text-align: left;
 margin: 0 20px;
}

.Faq h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 18.67px;
  color: #404041;
}

.Faq > div + div {
  border-top: 1px solid #64e3a1;
}
