.Header {
  background-color: #34b558;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.Header img {
  max-height: 46px;
}

.Header .Menu {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}

.Header .Menu div + div {
  margin-left: 20px;
}

.Header .Black {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.Header .White {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: white;
}

.Header .White a,
.Header .White a:active {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: white;
  text-decoration: none;
}

@media (min-width: 700px) {
  .Header {
    padding: 38px 50px;
    flex-direction: row;
    align-items: stretch;
  }
}

@media (min-width: 1150px) {
  .Header {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 1450px) {
  .Header {
    flex-direction: row;
    align-items: stretch;
  }
}
