.ConnectStep {
  background-color: #f4f7f9;
  padding: 25px;
}

.ConnectStep p {
  font-size: 16px;
  line-height: 24px;
  color: #404041;
  width: 100%;
}

.ConnectStep div {
  width: 100%;
}

.ConnectStep .textDanger {
  color: red;
}

.ConnectStep .small {
  font-size: 14px;
}

.ConnectStep .hidden {
  display: none;
}

.ConnectStep .Button {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 700px) {
  .ConnectStep {
    padding: 45px 60px;
  }
}
