.StripeInput {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  background: transparent;

  position: relative;
  width: 100%;
  padding: 25px 0;
}

.StripeInput label {
  pointer-events: none;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 28px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.StripeInput.HasFocus label {
  top: 6px;
  font-size: 14.67px;
  color: #34b558;
}

.StripeInput.Error label {
  color: red;
}

.StripeInput > div {
  border-bottom: 3px solid #34b558;
  padding: 5px 0;
}

.StripeInput.Error > div {
  border-bottom: 3px solid red;
}

@media (min-width: 480px) {
  .StripeInput.Half {
    width: calc(50% - 22px);
  }
}
