.CompanyInfo {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.CompanyInfo > p {
  margin-bottom: 5px;
}

.CompanyInfo .Forms {
  max-width: 600px;
  margin: auto;
}

.CompanyInfo .NotRequired {
  text-align: left;
}

.CompanyInfo .NotRequired h2 {
  color: #8e8e8e;
}

.CompanyInfo .NotRequired p {
  color: #8e8e8e;
}

.CompanyInfo .Next {
  margin-top: 40px;
  margin-bottom: 50px;
}

.StripeForm {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
