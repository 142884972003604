.Confirmation {
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.Confirmation p {
  margin-bottom: 5px;
}

.Confirmation .Next {
  margin-top: 40px;
  margin-bottom: 50px;
}
