.ErrorMessage {
  background-color: #f2ac31;
  display: flex;
  align-items: center;
  border-radius: 6.67px;
  padding: 25px 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: white;
  width: 100%;
}

.ErrorMessage img {
  display: block;
  width: 44px;
  height: 45px;
  margin-right: 25px;
}

.ErrorMessage div {
  width: auto !important;
}
