.Account {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.Account .Forms {
  max-width: 600px;
  margin: auto;
}

.Account .Next {
  margin-top: 40px;
  margin-bottom: 50px;
}
