.Select {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  position: relative;
  width: 100%;
  background: transparent;
}

.Select select {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  width: 100%;
  margin: 25px 0;
  border: none;
  border-bottom: 3px solid #34b558;
  outline: none;
  padding: 5px 0;
  color: #404041;
  background: transparent;
}

.Select span {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 30px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.Select.HasFocus span {
  top: 6px;
  font-size: 14.67px;
  color: #34b558;
}

.Select.Error select {
  border-bottom: 3px solid red;
}

.Select.Error span {
  color: red;
}

@media (min-width: 480px) {
  .Select.Half {
    width: calc(50% - 22px);
  }
}
