.CarrierButton {
  width: 100%;
  height: 142px;
  background-color: #f4f7f9;
  border-radius: 13.33px;
  border: none;
  outline: none;
  padding: 40px 15px;
  cursor: pointer;
  position: relative;
  margin-bottom: 12.5px;
}

.CarrierButton img.Logo {
  max-width: 100%;
  max-height: 100%;
}

.CarrierButton .AddButtonInactive {
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: -12.5px;
  left: calc(50% - 12.5px);
}

.CarrierButton:hover .AddButtonInactive {
  display: none;
}

.CarrierButton .AddButton {
  display: none;
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: -12.5px;
  left: calc(50% - 12.5px);
}

.CarrierButton:hover .AddButton {
  display: block;
}
